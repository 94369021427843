import React from 'react'
import Conditions from '../components/Conditions';
import styled from 'styled-components';
import Footer from '../components/Footer';
const MainTextContainer = styled.div`
width:40%;
margin:0 auto;
border:none;
height:100vh;
min-width:20rem;
@media(max-width:614px){
    width:90%;
    margin:0 auto;
}
`
const InnerContainer = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`
const AboutText = styled.span`
width:100%;
margin-top:10rem;
display:flex;
justify-content:center;
text-align:justify;
color:black;
font-size:20px;
`
const AboutTextInfo = styled.span`
width:100%;
margin-top:1.2rem;
display:flex;
justify-content:center;
text-align:justify;
color:black;
font-size:18px;
`
const About = () => {
  return <>
    <Conditions />  
    <MainTextContainer>
        <InnerContainer>
        <AboutText>Abous us</AboutText>
        <AboutTextInfo>
        We’re The Fairy Homes and we’ve been doing Real Estate Work in United Arab Emirates since 2018.
        We believe in only sourcing ethically Selling and give our best to our customers at a fair price.
        We started this business because I was tired of Seeing overpriced Houses and We believed there had
         to be a better way.
        We’re looking forward to meet with 2 more Real Estate Agencies in Dubai. Anything goes here, offer
         a free consultation if they fill out your form, tell them to call you.
        </AboutTextInfo>
        </InnerContainer>
    </MainTextContainer>
    <Footer />
    </>
}

export default About;