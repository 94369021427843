import styled from "styled-components";
import * as Mui from "@mui/material";
import MainBg from "../assets/main-bg.png";

export const BoxWrapperCenter = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20rem;
`;
export const MainDivCont = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@900;700&family=Poppins&family=Roboto:wght@100&display=swap");
  font-family: "Poppins", sans-serif;
`;
export const MainDiv = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&family=Poppins&display=swap");
  font-family: "Lato", sans-serif;
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Poppins&family=Roboto:wght@100&display=swap");
  // font-family: 'Roboto', sans-serif;
  font-family: "Poppins", sans-serif;
  display: flex;
  min-width: 20rem;
  width: 70%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1180px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 20rem;
  border: none;
`;

export const ColumnDivForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 20rem;
  width: -webkit-fill-available;
`;

export const RowDivWithPadding = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const RowDivCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PropertyCardParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const PropertyCardContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 10px;
`;

export const LandingFeatureRowDiv = styled.div`
  background-image: url(${MainBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 2%;
  border-radius: 5px;
`;

// ANCHOR Search
export const ColumnDivSearch = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 2%;
  flex-direction: column;
`;
export const SearchDiv = styled.div`
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
`;
export const SearchBackgroundImageDiv = styled.div`
  background-image: url(${MainBg});
  background-attachment: fixed;
  background-size: cover;
  min-width: 20rem;
  // TODO - Fix image scaling for background
  /* background-size: 100%; */
  padding: 15% 23% 20% 23%;
  @media (max-width: 850px) {
    padding: 15% 10px 20% 10px;
  }
  border-radius: 5px;
`;

// ANCHOR navigation
export const NavigationDiv = styled.div`
  padding: 20px 0 20px 0;
  @media (max-width: 850px) {
    padding: 20px 0 20px 0;
  }
  display: flex;
  flex-flow: row;
  flex: 2;
`;
export const NavButtonsDiv = styled.div`
  display: flex;
  flex-flow: row;
  flex-grow: 2;
  max-width: none;
  justify-content: flex-end;
  align-items: center;
`;
export const NavLogoDiv = styled.div`
  background-image: url(${MainBg});
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivSpaceBetweenHorizontal = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: none;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-around;
`;

export const Price = styled.div`
  padding-top: 9px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 9px;

  color: olivedrab;
  font-weight: bold;
  font-size: x-large;
`;

export const DivMarginUb = styled.div`
  margin-up: ${(props) => props.UpBottomMargin};
  margin-bottom: ${(props) => props.UpBottomMargin};
`;

export const DivMarginLf = styled.div`
  margin-left: ${(props) => props.leftRightMargin};
  margin-right: ${(props) => props.leftRightMargin};
`;

export const DivVertical = styled.div`
  display: flex;
  direction: coloumn;
`;

export const GoogleMapReactWrapper = styled.div`
  height: 20rem;
  width: 100%;
  margin: 1rem 0 0.5rem 0;
`;

export const NavButton = styled(Mui.Button)({
  paddingTop: "10px",
  paddingBottom: "10px",
  height: "50px",
});
export const RadioGroup = styled(Mui.RadioGroup)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 10,
});

export const BannerText = styled(Mui.Typography)({
  display: "flex",
  justifyContent: "center",
  minWidth: "20rem",
});

export const Loading = styled(Mui.LinearProgress)({
  borderRadius: 5,
  minHeight: 10,
});
