import React from 'react'
import styled from 'styled-components';
import pagenotfound from '../assets/pagenotfound.jpg';
import Conditions from './Conditions';
import Footer from './Footer';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate } from "react-router-dom";
const NotFoundContainer = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin-top:5rem;
`
const GoToHome = styled.button`
position:absolute;
width:100%;
top:10rem;
left:0;
right:0;

`
const NotFound = () => {
  const navigate = useNavigate();
  return <>
      <Conditions />
      <NotFoundContainer>
        <GoToHome>
      <Button variant="contained" startIcon={<HomeIcon htmlColor='blue'/>}  onClick={()=> navigate('/',{replace:true})}>
        Go Home
      </Button>
      </GoToHome>
      <img src={pagenotfound} alt="404 Error" width="100%" height="auto"/>
      </NotFoundContainer>
      <Footer />
    </>
}

export default NotFound;