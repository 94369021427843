import React from 'react'
import styled from 'styled-components';
import logo from '../assets/fairyhomes.png';
import { useNavigate } from 'react-router-dom';
const MainConditionContainer = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
position:fixed;
height:6rem;
z-index:999;
top:0;
left:0;
bottom:0;
background:white;
box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
`
const Logo = styled.img`
width:10rem;
height:5rem;
cursor:pointer;
`
const Conditions = () => {
  const navigate = useNavigate();
  return <>
    <MainConditionContainer>
                    <Logo src={logo} alt='Fairy Homes' onClick={()=> navigate('/')}/>
    </MainConditionContainer>
    </>
}

export default Conditions;