import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDmmbcJj3FhAqE2JMhEAqsX-fAVg-1T1Lc",
  authDomain: "fairyhomes-12ab0.firebaseapp.com",
  projectId: "fairyhomes-12ab0",
  // storageBucket: "fairyhomes-12ab0.appspot.com",
  messagingSenderId: "759693217110",
  appId: "1:759693217110:web:fdb5f03243080e65575af7",
  measurementId: "G-FE9HF3CT8F",
  storageBucket: "gs://fairyhomes-12ab0.appspot.com",
};

export const app = initializeApp(firebaseConfig);

// export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
