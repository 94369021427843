import React from 'react'
import { useEffect, useState } from "react";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 550) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return <>
    {showButton && (
      <button onClick={scrollToTop} style={{
        position: 'fixed',
        bottom: '15px',
        right: '10px',
        color: '#2a48a4',
        background: 'transparent',
        cursor: 'pointer',
        border: 'none',
        '@media (max-width: 446px)': {
          marginRight: '0',
        },
      }}>
        <KeyboardDoubleArrowUpIcon sx={{fontSize:'52px'}}/>
      </button>
    )}
  </>
};

export default ScrollToTop;