import React from "react";
import {
  Bed,
  LocationOnOutlined,
  BathtubOutlined,
  FullscreenOutlined,
  LocalPhoneOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import PropertyPlaceholder from "../assets/property-placeholder.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

const CardContainer = styled.div`
  display: flex;
  flex-basis: 22%;
  margin-left: 1%;
  flex-direction: column;
  justify-content: center;
  min-width: 20rem;
  max-width: 100%;
  cursor: pointer;
  margin-top: 1%;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  &:hover {
    transition: transform 0.5s;
    transform: scale(1.02);
  }
  @media (max-width: 800px) {
    display: flex;
  }
`;
const ImageTopCont = styled.div`
  width: 100%;
  height: 15rem;
  min-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageContainer = styled.img`
  width: 100%;
  height: 15rem;
  border-radius: 10px;
`;
const AEDContainer = styled.div`
  width: 100%;
  margin: 0.5rem 0 0.3rem 0rem;
  /* display: flex; */
`;
const AEDSpan = styled.span`
  width: 10rem;
  min-width: 10rem;
  max-width: 10rem;
  padding: 0.2rem;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 0.5rem;
  color: #2a48a4;
`;

const CardTitle = styled.h4`
  width: 98%;
  margin: 0.3rem 0 0.5rem 0.4rem;
  font-weight: 500;
  font-size: 16px;

  text-overflow: ellipsis;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  color: black;
`;
const CardLocationIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.3rem 0 0.5rem 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
`;
// const AddedAgo = styled.div`
//   width: 100%;
//   color: #82817d;
//   margin: 0 0 0.7rem 0.8rem;
//   font-weight: lighter;
//   font-size: 14px;
// `;
const PropertyItems = styled.div`
  display: flex;
  width: 90%;
  margin: 0.5rem 0 0.5rem 0.4rem;
  flex-direction: row;
  justify-content: space-between;
`;

const AgencyItems = styled.div`
  display: flex;
  width: 98%;
  border-radius: 10px;
  height: 30px;
  margin: 0.2rem 0 0.2rem 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
`;

const PropertyEachItem = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

const AgencyPhone = styled.div`
  display: flex;
  border-radius: 10px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & :hover,
  & :focus {
    height: 30px;
    width: 35px;
  }
`;

const AgencyEmail = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & :hover,
  & :focus {
    height: 30px;
    width: 35px;
  }
`;

// const PhoneText = styled.span`
//   display: none;
//   ${AgencyPhone}:hover & {
//     display: block;
//     position: absolute;
//     padding: 0.2rem;
//     border-radius: 4px;
//     font-weight: 500;
//     color: white;
//     background-color: #2a48a4;
//   }
// `;

// const EmailText = styled.span`
//   display: none;
//   ${AgencyEmail}:hover & {
//     display: block;
//     position: absolute;
//     padding: 0.2rem;
//     border-radius: 4px;
//     font-weight: 500;
//     color: white;
//     background-color: #2a48a4;
//   }
// `;
const PropertyCard = ({ property }) => {
  const navigate = useNavigate();

  function priceWithCommas(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <CardContainer onClick={() => navigate(`/ListingDetails/${property.id}`)}>
      <ImageTopCont>
        {
          <ImageContainer
            src={
              property.images.Image
                ? property.images.Image[0]
                : PropertyPlaceholder
            }
          />
        }
      </ImageTopCont>
      <AEDContainer>
        {property.price ? (
          <AEDSpan>AED {priceWithCommas(property.price)}</AEDSpan>
        ) : (
          "Loading..."
        )}
      </AEDContainer>
      <Divider sx={{ ml: 1, mr: 1 }} />
      <CardLocationIcon>
        <LocationOnOutlined sx={{ fontSize: "20px", color: "#2A48A4" }} />
        {`${property.subLocality}, ${property.city}`}
      </CardLocationIcon>
      <CardTitle>{property.title ? property.title : "Loading..."}</CardTitle>

      <PropertyItems>
        {property.bedrooms ? (
          <PropertyEachItem style={{ justifyContent: "" }}>
            <Bed
              sx={{ fontSize: "25px", marginRight: "0.3rem" }}
              htmlColor="#454040"
            />
            {property.bedrooms}
          </PropertyEachItem>
        ) : null}
        {property.bathrooms ? (
          <PropertyEachItem>
            <BathtubOutlined
              sx={{ fontSize: "20px", marginRight: "0.3rem" }}
              htmlColor="#454040"
            />
            {property.bathrooms}
          </PropertyEachItem>
        ) : null}
        {property.size ? (
          <PropertyEachItem>
            <FullscreenOutlined sx={{ fontSize: "25px" }} htmlColor="#454040" />
            {`${property.size} ${property.sizeUnit}`}
          </PropertyEachItem>
        ) : (
          "Loading..."
        )}
      </PropertyItems>
      <Divider sx={{ ml: 1, mr: 1 }} />
      <AgencyItems>
        {property.listingAgentPhone && (
          <AgencyPhone
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <a href={`tel:${property.listingAgentPhone}`}>
              <LocalPhoneOutlined
                sx={{
                  fontSize: "25px",
                  marginRight: "0.3rem",
                  color: "#454040",
                }}
              />
            </a>
          </AgencyPhone>
        )}
        {property.listingAgentEmail && (
          <AgencyEmail
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <a href={`mailto:${property.listingAgentEmail}`}>
              <EmailOutlined
                sx={{ fontSize: "25px", marginRight: "0.3rem" }}
                htmlColor="#454040"
              />
            </a>
          </AgencyEmail>
        )}
      </AgencyItems>
    </CardContainer>
  );
};
export default PropertyCard;
