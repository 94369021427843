import { Routes, Route } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { Suspense, lazy } from "react";
import { MainDivCont } from "./components/styledComponents";
import { CircularProgress } from "@mui/material";
import Listings from "./pages/Listings";
import "./styles/App.css";
import About from "./pages/About-us";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import NotFound from "./components/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase/FirebaseConfig";
import SignUp from "./pages/auth/SignUp";
import Signin from "./pages/auth/SignIn";

const AddListing = lazy(() => import("./pages/AddListing"));
const ListingDetails = lazy(() => import("./pages/ListingDetails"));

const App = () => {
  const [user, setUser] = useState(null);

  const signOutUser = () => {
    signOut(auth);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <MainDivCont>
      <Suspense fallback={<CircularProgress style={{ margin: "0 auto" }} />}>
        <Routes>
          <Route
            exact
            path="/"
            element={<Listings user={user} signOut={signOutUser} />}
          />
          <Route
            path="AddListing"
            element={<AddListing user={user} signOut={signOutUser} />}
          />

          <Route path="Signup" element={<SignUp />} />

          <Route path="Signin" element={<Signin />} />
          <Route
            path="ListingDetails/:id"
            element={<ListingDetails user={user} signOut={signOutUser} />}
          />
          <Route path="about-us" element={<About />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route
            path="*"
            element={<NotFound user={user} signOut={signOutUser} />}
          />
        </Routes>
      </Suspense>
      <ScrollToTop />
    </MainDivCont>
  );
};

export default App;
