import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { default as cities } from "../mock/cities.json";
import { SearchBackgroundImageDiv } from "../components/styledComponents";
import { BannerText } from "../components/styledComponents";
import PropertyCardGrid from "../components/PropertyCardGrid";
import styled from "styled-components";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button } from "@mui/material";
import {
  collection,
  query,
  where,
  getDocs,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";
import LinearProgress from "@mui/material/LinearProgress";
import FormikSearchV2 from "../components/FormikSearchV2";

const PropertyLayout = styled.div`
  overflow: hidden;
  ${({ toggle }) =>
    toggle === 1
      ? `
  width:90%;
  margin:0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 20rem;
  border: none;
  @media(max-width:1297px){
    justify-content:center;
  }`
      : `
  width:95%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap:wrap;
  justify-content: center;
  min-width: 20rem;
  border: none;
  overflow:hidden;
  `}
`;
// const ToggleAlter = styled.div`
//   overflow: hidden;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   width: 90%;
//   margin: 1rem 0 0 1rem;
//   @media (max-width: 800px) {
//     display: none;
//   }
// `
// const IconDiv = styled.div`
//   font-size: 25px;
//   cursor: pointer;
//   color: black;
//   &:hover {
//     color: #848482;
//   }
// `
const LoadMoreDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
`;
// const LoadButton = styled.button`
//   padding: 1rem;
//   background: #2a48a4;
//   color: white;
//   border: none;
//   cursor: pointer;
//   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
//     rgb(209, 213, 219) 0px 0px 0px 1px inset;
// `
const ErrorMessage = styled.span`
  width: 80%;
  text-align: center;
  font-size: 25px;
  color: black;
  font-weight: 700;
`;

const LOAD_MORE_INCREEMENT = 10;
const PROPERTIES_ORDER_BY = "locality";
const Listings = ({ user, signOut }) => {
  const queryParams = useLocation().search;
  const [proptertiesList, setPropertiesList] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(true);
  const [error, setError] = useState(null);
  const [queryData, setQueryData] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);

  const getPropertiesList = async (filter) => {
    let docs = [];
    const propertiesRef = collection(firestore, "properties");

    const queryConstraints = [];

    if (filter.city) queryConstraints.push(where("city", "==", filter.city));
    if (filter.location)
      queryConstraints.push(where("locality", "==", filter.location));
    if (filter.beds)
      queryConstraints.push(where("bedrooms", "==", filter.beds));
    if (filter.baths)
      queryConstraints.push(where("bathrooms", "==", filter.baths));
    if (filter.area) queryConstraints.push(where("size", "==", filter.area));
    if (filter.minPrice)
      queryConstraints.push(where("price", ">=", filter.minPrice));
    if (filter.maxPrice)
      queryConstraints.push(where("price", "<=", filter.maxPrice));

    let filterQuery = !lastVisible
      ? query(
          propertiesRef,
          ...queryConstraints,
          orderBy(PROPERTIES_ORDER_BY),
          limit(LOAD_MORE_INCREEMENT)
        )
      : query(
          propertiesRef,
          ...queryConstraints,
          orderBy(PROPERTIES_ORDER_BY),
          startAfter(lastVisible),
          limit(LOAD_MORE_INCREEMENT)
        );

    const querySnapshot = await getDocs(filterQuery);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });

    return docs;
  };

  const updatePropertiesDetails = async (filter) => {
    try {
      setIsPropertiesLoading(true);
      const response = await getPropertiesList(filter);
      if (response) {
        setPropertiesList([...proptertiesList, ...response]);
        setIsPropertiesLoading(false);
      }
    } catch (e) {
      setIsPropertiesLoading(false);
      console.error(e);
      setError("Sorry,Services Down for Some Reason,Kindly Try Again Later");
    }
  };

  useEffect(() => {
    if (!queryData) return;

    updatePropertiesDetails(queryData);
  }, [queryData]);

  useEffect(() => {
    const getQueryData = (queryParams) => {
      const params = new URLSearchParams(queryParams);

      let queryData = {
        city: params.get("city"),
        location: params.get("location"),
        beds: parseInt(params.get("beds")),
        baths: parseInt(params.get("baths")),
        area: parseInt(params.get("area")),
        minPrice: parseInt(params.get("minPrice")),
        maxPrice: parseInt(params.get("maxPrice")),
        type: params.get("type"),
      };
      return queryData;
    };

    setQueryData(getQueryData(queryParams));
  }, [queryParams]);

  return (
    <>
      <Navbar user={user} signOut={signOut} />
      <div>
        <SearchBackgroundImageDiv>
          <BannerText
            variant="h4"
            sx={{
              mb: "2%",
              color: "white",
              "@media(max-width:595px)": { marginTop: "3rem" },
            }}
          >
            Browse properties
          </BannerText>
          <FormikSearchV2 cities={cities} />
        </SearchBackgroundImageDiv>
        {/* {
          <ToggleAlter>
            <IconDiv onClick={() => setToggle(1)} toggle={toggle}>
              <ViewComfyIcon sx={{ fontSize: '30px' }} />
            </IconDiv>
            <IconDiv onClick={() => setToggle(2)} toggle={toggle}>
              <ViewListIcon sx={{ fontSize: '30px' }} />
            </IconDiv>
          </ToggleAlter>
        } */}
        <div style={{ marginTop: "1.5rem" }}>
          {isPropertiesLoading && !lastVisible ? (
            <LinearProgress />
          ) : (
            <PropertyLayout toggle={toggle}>
              {proptertiesList ? (
                proptertiesList.length > 0 ? (
                  proptertiesList.map((property) => {
                    return (
                      <PropertyCardGrid key={property.id} property={property} />
                    );
                  })
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <SearchOffIcon
                        style={{
                          margin: "0 auto",
                          fontSize: "120px",
                          color: "#2a48a4",
                        }}
                      />
                      <p
                        style={{
                          margin: "0 auto",
                          fontSize: "25px",
                          color: "#2a48a4",
                        }}
                      >
                        No Results Found
                      </p>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SearchOffIcon
                      style={{
                        margin: "0 auto",
                        fontSize: "120px",
                        color: "#2a48a4",
                      }}
                    />
                    <p
                      style={{
                        margin: "0 auto",
                        fontSize: "25px",
                        color: "#2a48a4",
                      }}
                    >
                      No Results Found
                    </p>
                  </div>
                </>
              )}
              <LoadMoreDiv>
                <Button
                  sx={{ background: "#2a48a4" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={() => updatePropertiesDetails(queryData)}
                >
                  {isPropertiesLoading ? "Loading" : "Load More"}
                </Button>
              </LoadMoreDiv>
              {error ? (
                <div>
                  <ErrorMessage>{error}</ErrorMessage>
                </div>
              ) : null}
            </PropertyLayout>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Listings;
