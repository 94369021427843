import React, { useState } from "react";
import * as Mui from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import styled from "styled-components";
const BedBathAreaCont = styled.div`
  width: 31%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  @media (max-width: 581px) {
    width: 100%;
  }
`;

const MinMaxAreaCont = styled.div`
  width: 67%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  @media (max-width: 581px) {
    width: 100%;
  }
`;

const ColumnDivSearch = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 2%;
  flex-direction: column;
`;
const SearchDiv = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
`;

const noOfBedsArray = ["1", "2", "3", "4", "5", "6", "7+"];
const noOfBathsArray = ["1", "2", "3", "4+"];

const FormikSearchV2 = ({ cities }) => {
  const [type, setType] = useState("buy");

  const navigate = useNavigate();

  return (
    <SearchDiv>
      <ColumnDivSearch>
        <Formik
          initialValues={{
            location: null,
            city: null,
            beds: 0,
            baths: 0,
            MaxArea: 0,
            MinArea: 0,
            minPrice: 0,
            maxPrice: 0,
          }}
          onSubmit={(values) => {
            let queryData = {
              type: type,
            };

            if (values["city"]) {
              queryData["city"] = values["city"];
            }

            if (values["location"]) {
              queryData["location"] = values["location"];
            }

            Object.keys(values).map((key) =>
              values[key] > 0 ? (queryData[key] = values[key]) : null
            );
            const searchParams = new URLSearchParams(queryData);
            let path = `/?${searchParams}`;
            navigate(path);
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <Mui.ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={type}
                  name="type"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  sx={{
                    justifyContent: "center",
                    alignSelf: "center",
                    mb: "1%",
                    backgroundColor: "white",
                  }}
                >
                  <Mui.ToggleButton value="buy">Buy 💰</Mui.ToggleButton>
                  <Mui.ToggleButton value="rent">Rent 🏠</Mui.ToggleButton>
                </Mui.ToggleButtonGroup>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2%",
                  }}
                >
                  <Mui.TextField
                    name="location"
                    label="Location"
                    variant="outlined"
                    size="small"
                    sx={{
                      flex: 4,
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                    onChange={formikProps.handleChange}
                  />
                  <Mui.Autocomplete
                    name="city"
                    disablePortal
                    size="small"
                    onChange={(e, value) => {
                      formikProps.setFieldValue("city", value);
                    }}
                    options={cities}
                    sx={{
                      flex: 2,
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                    renderInput={(params) => (
                      <Mui.TextField {...params} label="City" required />
                    )}
                  />
                  <Mui.Button
                    sx={{ flex: 1, background: "#2a48a4" }}
                    type="submit"
                    variant="contained"
                    size="small"
                  >
                    Search
                  </Mui.Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "8px",
                    gap: "2%",
                    flexWrap: "wrap",
                  }}
                >
                  <BedBathAreaCont>
                    <Mui.Autocomplete
                      name="beds"
                      disablePortal
                      size="small"
                      onChange={(e, value) => {
                        if (value === "7+") {
                          formikProps.setFieldValue("beds", 7);
                        } else {
                          formikProps.setFieldValue("beds", value);
                        }
                      }}
                      options={noOfBedsArray}
                      sx={{
                        flex: 1,
                        backgroundColor: "white",
                        borderRadius: "5px",
                      }}
                      renderInput={(params) => (
                        <Mui.TextField {...params} label="Bedrooms" />
                      )}
                    />
                    <Mui.Autocomplete
                      name="baths"
                      disablePortal
                      size="small"
                      onChange={(e, value) => {
                        if (value === "4+") {
                          formikProps.setFieldValue("baths", 4);
                        } else {
                          formikProps.setFieldValue("baths", value);
                        }
                      }}
                      options={noOfBathsArray}
                      sx={{
                        flex: 1,
                        backgroundColor: "white",
                        borderRadius: "5px",
                      }}
                      renderInput={(params) => (
                        <Mui.TextField {...params} label="Bath" />
                      )}
                    />
                  </BedBathAreaCont>
                  <MinMaxAreaCont>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                        gap: "1%",
                      }}
                    >
                      <Mui.TextField
                        name="minArea"
                        label="Min Area"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        sx={{
                          flex: 1,
                          backgroundColor: "white",
                          borderRadius: "5px",
                        }}
                        onChange={formikProps.handleChange}
                      />
                      <Mui.TextField
                        name="maxArea"
                        label="Max Area"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        sx={{
                          flex: 1,
                          backgroundColor: "white",
                          borderRadius: "5px",
                        }}
                        onChange={formikProps.handleChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                        gap: "1%",
                      }}
                    >
                      <Mui.TextField
                        name="minPrice"
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        label="Min"
                        variant="outlined"
                        size="small"
                        sx={{
                          flex: 1,
                          backgroundColor: "white",
                          borderRadius: "5px",
                        }}
                        onChange={formikProps.handleChange}
                      />
                      <Mui.TextField
                        name="maxPrice"
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        label="Max"
                        variant="outlined"
                        size="small"
                        sx={{
                          flex: 1,
                          backgroundColor: "white",
                          borderRadius: "5px",
                        }}
                        onChange={formikProps.handleChange}
                      />
                    </div>
                  </MinMaxAreaCont>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ColumnDivSearch>
    </SearchDiv>
  );
};

export default FormikSearchV2;
